import { View, Text, Image,  StyleSheet } from 'react-native';
import { auth } from '../config/firebase';
import { useNavigation} from '@react-navigation/native';
import { onAuthStateChanged } from 'firebase/auth';
import React, {useEffect, useState}from 'react';
import colours from '../config/colours';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Footer from '../components/Footer';


export default function LandingPage() {
	const navigation = useNavigation();

    useEffect(()=>{
		window.scrollTo(0, 0);
        onAuthStateChanged(auth, (user)=>{
            if(user)
            {
                console.log("");
            }
            else{
                navigation.navigate("Login");
            }
			
			
        })
    },[])
  return (
    <View style={styles.body}>
        <Image source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644472755/App4u/innovative-business-technology-2021-09-02-06-00-42-utc_wkywqo.jpg'} style={styles.topImage}></Image>
        <View style={styles.blurbContainer}>
      <Text style={styles.topBlurb}>Welcome to App4u. Here at App4u we offer top tier website development and mobile development. We earn the trust and confidence of our clients every day by offering innovative state of the art software and design solutions. We pride ourselves in turning our clients’ visions into reality through our client-centric agile development methodologies. We excel at our job with superior communication, outstanding problem solving, and extremely competitive pricing models. With years of experience in building websites and mobile and desktop apps, and a rich technology skillset stack including Wix, Wordpress, React Native, React .js, Javascript, TypeScript, PhP, C#, C++, and multiple database and CRM systems. You can count on our capable team to deliver your project according to the plan and within your desired budget. Join our happy clients today!</Text>
      </View>
      <View style={styles.iconContainer}>
        <View  style={styles.iconBox}>
            <Image style={styles.icon} source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644475684/App4u/50-Design-Icons_2_9_jz4e6j.png'} />
            <Text style={styles.iconText}>State of the art custom designs specifically made for your branding</Text>
        </View>
        <View style={styles.iconBox}>
            <Image style={styles.icon} source={{uri:'https://res.cloudinary.com/kcb-software-design/image/upload/v1644475630/App4u/50-Design-Icons_2_14_db9xpe.png'}}/>
            <Text style={styles.iconText}>Mobile, ipad and desktop Friendly</Text>
        </View>
        <View style={styles.iconBox}>
            <Image style={styles.icon} source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644475620/App4u/50-Design-Icons_2_19_p8t25w.png'}/>
            <Text style={styles.iconText}>Support and tips on how to keep your brand current</Text>
        </View>
        <View style={styles.iconBox}>
            <Image style={styles.icon} source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644475644/App4u/50-Design-Icons_2_34_upicgm.png'}/>
            <Text style={styles.iconText}>With years of programming background, we can ensure you speed, security and optimization on all products. </Text>
        </View>
      </View>
      <View style={styles.helpBox}>
          <Text style={styles.helpTitle}>Have you spoken to one of our representatives yet to guide you to the right product for you?<br/></Text>
          <Text style={styles.helpText}>If not, please contact us so we can direct you further.<br/>Please email us at <a>information.app4u@gmail.com</a></Text>
      </View>
      <View style={styles.butContainer}>
            <TouchableOpacity onPress={()=>{navigation.navigate("Products")}} style={styles.button}>If You Have Spoken To A Representative,<br/><b>Browse Products</b></TouchableOpacity>
      </View>
	  <Footer/>
    </View>
  );
}
const styles = StyleSheet.create({
    body:{
        fontFamily: 'georgia bold',
    },
    topImage:{
        height: "450px",
        width: 'auto',
    },
    topBlurb:{
        fontFamily: 'georgia bold',
        width: "80%",
        lineHeight:"1.4",
        textAlign: 'center',
        color: 'white',
        fontSize: '18px',
    },
    blurbContainer:{
        
        paddingTop: "30px",
        paddingBottom: "30px",
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        backgroundColor: colours.third,
        opacity: '0.5',
    },
    iconContainer:{
        marginLeft: '2vw',
        marginTop: '50px',
        alignItems: 'center',
        textAlign: 'center',
        alignContent: ''
    },
    icon:{
        width: 150,
        height:150,
        marginTop: '100px',
        textAlign: 'center',
        // position: 'relative',
    },
    iconText:{
        fontFamily: 'georgia bold',
        fontSize: 18,
        maxWidth: '80%',
        // marginLeft: '20%',
        marginTop: '20px',
        textAlign: 'center',
        fontWeight: 'bold',

    },
    iconBox:{
        alignItems: 'center',
    },
    helpBox:{
        marginTop: '60px',
        alignContent: 'center',
        alignItems: 'center',
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: colours.third,
        opacity: 0.6,

        
    },
    helpTitle:{
        fontFamily: 'georgia bold',
        fontSize: 25, 
        textAlign: 'center',
        maxWidth: '70%',
        fontWeight: 'bold',
        color: 'white',
        
    },
    helpText:{
        fontFamily: 'georgia bold',
        fontSize: 18,
        textAlign: 'center',
        color: 'white',
    },
    button:{
        marginTop: '50px',
        textAlign: 'center',
        borderRadius: 30,
        backgroundColor: colours.forth,
        // width: '40%',
        color: 'white',
        padding: '50px',
        alignContent: 'center',
        fontSize: 20,
        lineHeight: '2',

    },
    butContainer:{
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: '200px',
    },
	footer:{
		height: '200px',
		backgroundColor: colours.third,
		
	},
	copyright:{
		color: 'white',
		position: 'absolute',
		bottom: 0,
		right: '37%',
		textAlign: 'center',

	},
	anchorContainer:{
		marginLeft: '20px',
		marginTop: '50px',
		width: '200px',
	},
	footeranchor:{
		color: 'white',
		padding:'10px',
		fontFamily: 'georgia bold',
		fontSize:15,
	},



})

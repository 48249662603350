import React, { useEffect, useState, useContext } from 'react';
import { View, Text, Button, FlatList, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { CartContext } from '../config/CartContext';
// import stripe from 'tipsi-stripe/src';
import colours from '../config/colours';


export function Cart () {
const {items, getItemsCount, getTotalPrice} = useContext(CartContext);
//  useEffect(()=>{
//     stripe.setOptions({
//         publishableKey: 'pk_test_51KOcilGn8i2V0O6vYSvHYWx3m5PQ4RYkekqmgTYBh0Y0OBgdvsunKSYRG0UTxNIOneFCTLUVNVt83DZ9gmKX4Eji001Erc3gad',
//     })
//   })

  function Totals() {
    let [total, setTotal] = useState(0);
    useEffect(() => {
      setTotal(getTotalPrice());
    });
    return (
       <View style={styles.cartLineTotal}>
          <Text style={[styles.lineLeft, styles.lineTotal]}>Total</Text>
          <Text style={styles.lineRight}>$ {total}</Text>
       </View>
    );
  }
function renderItem({item}) {
    return (
       <View style={styles.cartLine}>
          <Text style={styles.lineLeft}>{item.product.name} x {item.qty}</Text>
          <Text style={styles.lineRight}>$ {item.totalPrice}</Text>
       </View>
    );
  }

  return (
      <View>
    <FlatList
      style={styles.itemsList}
      contentContainerStyle={styles.itemsListContainer}
      data={items}
      renderItem={({item})=>(
        <View style={styles.cartLine}>
        <Text style={styles.lineLeft}>{item.product.name} x {item.qty}</Text>
        <Text style={styles.lineRight}>$ {item.totalPrice}</Text>
     </View>
      )}
      keyExtractor={(item) => item.product.id.toString()}
      ListFooterComponent={Totals}
    />
    <TouchableOpacity style={styles.checkout}>CheckOut</TouchableOpacity>
    </View>
  );
}
const styles = StyleSheet.create({
  cartLine: { 
    flexDirection: 'row',
  },
  cartLineTotal: { 
    flexDirection: 'row',
    borderTopColor: '#dddddd',
    borderTopWidth: 1
  },
  lineTotal: {
    fontWeight: 'bold',    
  },
  lineLeft: {
    fontSize: 20, 
    lineHeight: 40, 
    color:'#333333' 
  },
  lineRight: { 
    flex: 1,
    fontSize: 20, 
    fontWeight: 'bold',
    lineHeight: 40, 
    color:'#333333', 
    textAlign:'right',
  },
  itemsList: {
    backgroundColor: '#eeeeee',
  },
  itemsListContainer: {
    backgroundColor: '#eeeeee',
    paddingVertical: 8,
    marginHorizontal: 8,
  },
  checkout:
  {
      backgroundColor: colours.secondary,
      padding: '50px',
      width: '25%',
      alignContent: 'center',
  }
});
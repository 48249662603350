import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

export default function Terms() {
  return (
	<View>
	  <Text style={styles.text}>TERMS AND CONDITIONS<br/>

These terms and conditions (the "Terms and Conditions") govern the use of app4u.ca. This Site is owned and operated by Katherine Brough. This Site is an ecommerce website.<br/><br/>

By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.<br/><br/>

THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.<br/><br/>

Intellectual Property<br/>
All content published and made available on our Site is the property of Katherine Brough and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.<br/><br/>

Acceptable Use<br/>
As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:<br/><br/>

Harass or mistreat other users of our Site;<br/>
Violate the rights of other users of our Site;<br/>
Violate the intellectual property rights of the Site owners or any third party to the Site;<br/>
Hack into the account of another user of the Site;<br/>
Act in any way that could be considered fraudulent; or<br/>
Post any material that may be deemed inappropriate or offensive.<br/>
If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.<br/><br/>

User Contributions<br/>
Users may post the following information on our Site:<br/><br/>

Items for sale; and<br/>
Public comments.<br/>
By posting publicly on our Site, you agree not to act illegally or violate these Terms and Conditions.<br/><br/>

Accounts<br/>
When you create an account on our Site, you agree to the following:<br/><br/>

You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and<br/>
All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.<br/>
We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.<br/><br/>

Sale of Goods<br/>
These Terms and Conditions govern the sale of goods available on our Site.<br/><br/>

The following goods are available on our Site:<br/><br/>

Websites; and<br/>
Applications.<br/>
These Terms and Conditions apply to all the goods that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All information, descriptions, or images that we provide about our goods are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all goods we provide. You agree to purchase goods from our Site at your own risk.<br/><br/>

We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.<br/><br/>

Third Party Goods and Services<br/>
Our Site may offer goods and services from third parties. We cannot guarantee the quality or accuracy of goods and services made available by third parties on our Site.<br/><br/>

User Goods and Services<br/>
Our Site allows users to sell goods and services. We do not assume any responsibility for the goods and services users sell on our Site. We cannot guarantee the quality or accuracy of any goods and services sold by users on our Site. However, if we are made aware that a user is violating these Terms and Conditions, we reserve the right to suspend or prohibit the user from selling goods and services on our Site.<br/><br/>

Payments<br/>
We accept the following payment methods on our Site:<br/><br/>

Credit Card.<br/>
When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due to this payment instrument.<br/><br/>

If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.<br/><br/>

Consumer Protection Law<br/>
Where the Business Practice and Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.<br/><br/>

Limitation of Liability<br/>
Katherine Brough and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.<br/><br/>
Cancellation Policy<br/>
You may terminate the Agreement due to the non-performance by us upon providing us with no less than 30 days prior written notice, (the “Client Notice”), subject to the following;<br/><br/>

1.The Client Notice shall clearly outline the specifics of our non-performance.  <br/>
2.Upon our receipt of the Client Notice, we shall have no less than 14 days to rectify or cure issue outlined in the Client Notice, to the sole satisfaction of you.  <br/>
3.In the event you, acting reasonably, are not satisfied that the issue has been cured, this Agreement shall terminate on the date outlined in the Client Notice.<br/>
4.Upon such termination, this Agreement shall be null and void and of no further effect and without recourse for any associated costs by either party, one to the other.<br/>
In the event we breach a material provision under this Agreement, you may suspend or terminate this Agreement immediately and require us to return the Deposit or any funds withstanding (deducting any labour or work that has already been done on project), if any, immediately to you. Upon such termination, this Agreement shall be null and void and of no further effect and without recourse for any associated costs by either party, one to the other.<br/><br/>

	In the event you breach a material provision under this Agreement, (including non-payment of fees), we may suspend or terminate this Agreement immediately and, if terminated may retain the Deposit in full.  <br/><br/>

	Except as otherwise provided in this Agreement, the obligations you and us shall end upon the Expiry Date arranged, unless extended by mutual agreement of both you and us.<br/><br/>


 Refund Policy<br/>
Our policy is valid for a period of 3 calendar days from the date of the purchase. If you are not satisfied with the service, you can ask for a refund. A pro-rated refund will be issued for the unused portion of the services. If the period of 3 days has lapsed since the purchase, we can’t, unfortunately, offer you a refund.<br/>
	Refund requirements: The following criteria must be met to qualify for a refund:<br/>
Service must not be used<br/>
 Service malfunctions or doesn’t work as described<br/><br/>

If the conditions listed above are not met, we reserve the right not to issue a refund. It’s important to keep in mind that there is often a difference between a service that  doesn’t work and a situation where you are receiving an error message. Error messages could be related to an incorrect setup, configuration or software and as a result the service is not working.<br/><br/>
Additional services:<br/>
 Please note that any additional services, custom work or technical support are non-refundable as our time cannot be recovered.<br/><br/>

Indemnity<br/>
Except where prohibited by law, by using this Site you indemnify and hold harmless Katherine Brough and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.<br/><br/>

Applicable Law<br/>
These Terms and Conditions are governed by the laws of the Province of British Columbia.<br/><br/>

Dispute Resolution<br/>
Subject to any exceptions specified in these Terms and Conditions, if you and Katherine Brough are unable to resolve any dispute through informal discussion, then you and Katherine Brough agree to submit the issue first before a non-binding mediator and to an arbitrator in the event that mediation fails. The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be a neutral party acceptable to both you and Katherine Brough. The costs of any mediation or arbitration will be shared equally between you and Katherine Brough.<br/><br/>

Notwithstanding any other provision in these Terms and Conditions, you and Katherine Brough agree that you both retain the right to bring an action in small claims court and to bring an action for injunctive relief or intellectual property infringement.<br/><br/>

Severability<br/>
If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.<br/><br/>

Changes<br/>
These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.<br/><br/>

Contact Details<br/>
Please contact us if you have any questions or concerns. Our contact details are as follows:<br/><br/>


information.app4u@gmail.com<br/><br/><br/>


You can also contact us through the feedback form available on our Site.<br/><br/>

Effective Date: 10th day of February, 2022<br/><br/>

©2002-2022 LawDepot.ca®</Text>
	</View>
  )
}
const styles = StyleSheet.create({
	text:{
		marginTop: '40px',
		marginLeft: '50px',
		textAlign: 'center',
	}
})

 export const WebData =
 [
    {
       id: 1,
       name: 'Landing Page',
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1643923501/App4u/website-development-2021-09-04-07-39-08-utc_azkf9v.jpg',
       price: 500.00,
       details: 'One custom made, simple Landing Page website. This includes a full custom made Landing page with SEO, images, text, animations, graphics and very limited backend capabilities.Limits do apply. Contact your web developer to determine which Plan is right for you and your needs.',
       button: 'https://square.link/u/EX7qzsWs',
        
       
    },
    {
       id: 2,
       name: 'Five Page Website',
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1643923487/App4u/ux-designer-creating-website-layout-2021-09-01-07-48-02-utc_b0thkf.jpg',
       price: 1500.00,
       details: 'One custom made, simple Five Page Website. Includes limited functionally, mostly text, animations and images with "About Us" section and any pages up to 5 pages that the client wants. This does not include APIs or nodemailer hook up for a contact us or backend work. A full customizable design and layout with be included with 5 design changes. This also includes the website being responsive and able to use and navigate easily on mobile devices as well as iPad, laptop and desktop. Limits do apply. Contact your web developer to determine which Plan is right for you and your needs.',
       button: 'https://square.link/u/yF8ASZYo',
    },
    {
       id: 3,
       name: "Custom Website",
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1643923493/App4u/web-design-software-2021-08-26-22-39-49-utc_ei2d6h.jpg',
       price: 3000.00,
       details: 'One Full Custom Website. Includes additional features for support and API and technical background capabilities. This includes but not limited to nodemailer, AWS or firebase, with database, cloud functions, authentication and admin dashboards for authenticated users. News or blog pages for updating information. Includes 5 design changes.Limits do apply. Contact your web developer to see what plan is right for you and your needs.',
       button: "https://square.link/u/dVDI4PAe",
    },
    {
       id: 4,
       name: "Ecommerce Website",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1643923505/App4u/unrecognizable-businesswoman-using-tablet-with-onl-2021-08-29-07-44-52-utc_djy4tr.jpg",
       price: 5000.00,
       details: 'One Custom E-Commerce Website. This includes a full custom Ecommerce website with additional features for support and API and technical background capabilities. This includes but not limited to nodemailer, AWS or firebase, with database, cloud functions, authentication and admin dashboards for authenticated users. Ability to add, edit and delete products, ability to update text on website. Videos and brand content up to date. Full marketplace with payment processing and order fulfillments. Support available within 24 hours on any issues. Includes 5 design changes. Limits do apply, contact your web developer to see what plan is right for you and your needs.',
       button: 'https://square.link/u/oWFTx04q',
    },
    // {
    //    id: 5,
    //    name: "Logo Design",
    //    image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1646255030/App4u/designer-photographing_soa3xm.jpg",
    //    price: 'custom price',
    //    details: 'One Custom Made Logo Design. One Logo to represent your brand and business. Talk to your graphic designer for payment details. Fill in the input field with the  price gave to you by your graphic designer.',
    //    button: 'https://square.link/u/oWFTx04q',
    // },
    // {
    //    id: 6,
    //    name: "TESTING 5$ Amount",
    //    image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1646255030/App4u/designer-photographing_soa3xm.jpg",
    //    price: 5.00,
    //    details: '5$ amount testing',
    //    button: 'https://square.link/u/oWFTx04q',
    // },
]

    export const AppData =[
    {
       id: 6,
       name: "Mobile App Design Phase",
       price: 5500.00,
    },
    {
       id: 7,
       name: "Small Mobile App",
       price: 10000.00,
    },
    {
       id: 8,
       name: "Small Mobile App with multiple API's",
       price: 17000,
    },
    {
       id: 9,
       name: "Medium Mobile App",
       price: 50000.00,
    },
    {
       id: 10,
       name: "Medium Mobile App with extra Additions",
       price: 70000.00,
    },
    {
       id: 11,
       name: "Large Mobile App",
       price: 100000.00,
    },
    {
       id: 12,
       name: "Large Mobile App with extra Additions",
       price: 150000.00,
    },
    {
       id: 13,
       name: "Large Mobile App with extra Additions and Support",
       price: 200000.00,
    },
    ]

export function getProducts(){
    return WebData && AppData;
}
export function getProduct(id){
    if(id<7){
        return WebData.find((e) => (e.id == id));
    }
    else{
        return AppData.find((e) => (e.id == id));
    }
}

import { View, Text, TextInput,TouchableOpacity, StyleSheet } from 'react-native'
import React, { Component } from 'react'
import { getFunctions, httpsCallable} from 'firebase/functions';
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import {app} from '../config/firebase';
import colours from '../config/colours';
import Footer from '../components/Footer';
const firebaseFunctions = getFunctions(app);
const firestore = getFirestore();


export default class fillout extends Component {
	constructor(){
		super();
		this.state={
			Name: '',
			company: '',
			email: '',
			phone: '',
			street: '',
			city: '',
			country: '',
			postal: '',
			price: '',
			cardnumber: '',
			expiry: '',
			cvv: '',

		}
	}
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
	  updateInputVal = (val, prop) => {
        const state = this.state;
        state[prop] = val;
        this.setState(state);
      }
	  onSubmit =()=>{
		if(this.state.email == '' || this.state.Name == '' || this.state.company == '' || this.state.phone == '' || this.state.street == '' || this.state.city == '' || this.state.country == '' || this.state.postal == '' || this.state.price == '' || this.state.cardnumber == ''|| this.state.expiry == '' || this.state.cvv == '') {

			alert('Please enter all required fields')
		  } 
		  else{
		// this.textInput.clear();
		this.nameInput.clear();
		this.busInput.clear();
		this.phoneInput.clear();
		this.emailInput.clear();
		this.streetInput.clear();
		this.cityInput.clear();
		this.countryInput.clear();
		this.postalInput.clear();
		this.priceInput.clear();
		this.countryInput.clear();
		this.expiryInput.clear();
		this.cvvInput.clear();
		const sendInfo = httpsCallable(firebaseFunctions, "sendInfo", app);
			sendInfo({
				name: this.state.Name,
				company: this.state.company,
				email: this.state.email,
				phone: this.state.phone,
				street: this.state.street,
				city: this.state.city,
				country: this.state.country,
				postal: this.state.postal,
				price: this.state.price,
				cardnumber: this.state.cardnumber,
				expiry: this.state.expiry,
				cvv: this.state.cvv,
			}).then(result =>{
				console.log("email sending result:" + result);
				alert("Email Sent Successfully. We will contact you within 24 hours");
			}
		).catch(error =>{
				console.log(error);
				console.log("frontend error");
				console.log(error.code);
				console.log(error.message);
				console.log(error.details);
			})

		}
		  
	  }
  render() {
	return (
		<View>
		<View style={styles.container}>
			<Text style={styles.label}>Please fill out the form and we will contact you within 24 hours with an invoice for your payment.<br/> <br/>Make sure all info is the same information on your credit card.</Text>
	  <View style={styles.form}>
		  <Text style={styles.label}>Full Name</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.nameInput = input }}
		  placeholder="Full Name"
		  value={this.state.Name}
		  onChangeText={(val)=> this.updateInputVal(val, 'Name')}
		  /><br/>
		  <Text style={styles.label}>Business Name</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.busInput = input }}
		  placeholder="Business Name"
		  value={this.state.company}
		  onChangeText={(val)=> this.updateInputVal(val, 'company')}
		  /><br/>
		  <Text style={styles.label}>Email</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.emailInput = input }}
		  placeholder="Email"
		  value={this.state.email}
		  onChangeText={(val)=> this.updateInputVal(val, 'email')}
		  /><br/>
		  <Text style={styles.label}>Phone Number</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.phoneInput = input }}
		  placeholder="Phone Number"
		  value={this.state.phone}
		  onChangeText={(val)=> this.updateInputVal(val, 'phone')}
		  /><br/>
		  <Text style={styles.label}>Street Address</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.streetInput = input }}
		  placeholder="Street Address"
		  value={this.state.street}
		  onChangeText={(val)=> this.updateInputVal(val, 'street')}
		  /><br/>
		  <Text style={styles.label}>City</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.cityInput = input }}
		  placeholder="City"
		  value={this.state.city}
		  onChangeText={(val)=> this.updateInputVal(val, 'city')}
		  /><br/>
		   <Text style={styles.label}>Country</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.countryInput = input }}
		  placeholder="Country"
		  value={this.state.country}
		  onChangeText={(val)=> this.updateInputVal(val, 'country')}
		  /><br/>
		   <Text style={styles.label}>Zip/Postal Code</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.postalInput = input }}
		  placeholder="Postal Code/ Zip Code"
		  value={this.state.postal}
		  onChangeText={(val)=> this.updateInputVal(val, 'postal')}
		  /><br/>
		   <Text style={styles.label}>Price of project bring purchased</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.priceInput = input }}
		  placeholder="price"
		  value={this.state.price}
		  onChangeText={(val)=> this.updateInputVal(val, 'price')}
		  /><br/>
		   <Text style={styles.label}>Credit Card Number</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.countryInput = input }}
		  placeholder="Enter Card Number"
		  value={this.state.cardnumber}
		  onChangeText={(val)=> this.updateInputVal(val, 'cardnumber')}
		  /><br/>
		   <Text style={styles.label}>Expiry date (mm/yy)</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.expiryInput = input }}
		  placeholder="mm/yy"
		  value={this.state.expiry}
		  onChangeText={(val)=> this.updateInputVal(val, 'expiry')}
		  /><br/>
		   <Text style={styles.label}>CVV (numbers on back of credit card)</Text>
		  <TextInput
		  style={styles.input}
		  ref={input => { this.cvvInput = input }}
		  placeholder="cvv"
		  value={this.state.cvv}
		  onChangeText={(val)=> this.updateInputVal(val, 'cvv')}
		  /><br/>
		 
	
		  <TouchableOpacity
		  style={styles.button}
		  onPress={()=> this.onSubmit()}>Submit</TouchableOpacity>
	  </View>

	</View>

	</View>
	)
  }
}
const styles = StyleSheet.create({
	container:{
		alignContent: 'center',
		alignItems: 'center',
	},
	form:{
		marginTop: "40px",
		padding: '40px',
		backgroundColor: colours.secondary,
		width: '80%',
		borderRadius: 10,
	},
	input:{
		backgroundColor:'white',
	},
	button:{
		textAlign: 'center',
		backgroundColor: colours.third,
		padding: '30px',
		borderRadius:15,
		fontWeight: 'bolder'
	},
	label:{
		fontWeight: 'bold',
		fontFamily: 'georgia bold',

	}

})
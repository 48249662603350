import React, {useEffect, useState, useContext} from 'react';
import {Text, Image,  Linking, View, ScrollView, SafeAreaView, Clipboard, Button, StyleSheet} from 'react-native';
import { useNavigation} from '@react-navigation/native';
// import { getFunctions, httpsCallable} from 'firebase/functions';
// import {app} from '../config/firebase';
import { getProduct } from '../config/cartData';
import { CartContext } from '../config/CartContext';
import { TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { FontAwesome } from '@expo/vector-icons';
// import {WebView} from 'react-native-webview';
import * as Crypto from 'expo-crypto';
import moment from 'moment';
import colours from '../config/colours';
// const firebaseFunctions = getFunctions(app);

export function ProductDetails({route}) {
const navigation = useNavigation();
const terminalID = '1064140';
  const { productId } = route.params;
  const [packageI, setProduct] = useState({});
  const [price, setPrice] = useState('');
  const [copiedText, setCopiedText]= useState('');
  const [timer, setTimer] = useState(0);
  const PRICEREF = React.useRef();

  const { addItemToCart } = useContext(CartContext);

  useEffect(() => {
    setProduct(getProduct(productId));
	console.log(price);
	settingPrice()
	// if(timer <= 3)
	// {
	// 	setTimer((v)=> v + 1);
	// 	settingPrice(packageI.id);
	// }
});
const settingPrice = async ()=>{
	const datey = moment().format('DD-MM-YYYY:hh:mm:ss:sss');
	const date = datey.slice(0, -1);
	const hasheddate = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA512, datey);
	const hashedslice = hasheddate.substring(20,15);
	console.log(hashedslice);

  }

//   function onAddToCart() {
//     addItemToCart(packageI.id);
//   }
   const SubmitInfo = async (e) =>{
		// if(packageI.id != 5)
		// {
			navigation.navigate("Form", {productId: packageI.id,})
			// console.log(" pack id NOT 5")
		// 	const amount = packageI.price;
		// 	const datey = moment().format('DD-MM-YYYY:hh:mm:ss:sss');
		// 	const date = datey.slice(0, -1);
		// 	const hasheddate = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, date);
		// 	const hashedslice = hasheddate.substring(20,15);
		// const terminalID = '56614001';
		// const currency = "USD";
		// const OrderId = hashedslice;
		// const secret ='neverlandherewecome2910';
		// const receiptpage = 'https://kcbsoftware.com/app4ureceipt';

		// const hashFORMATTED = `${terminalID}:${OrderId}:${currency}:${amount}:${date}:${receiptpage}:${secret}`;
		// const digest = await Crypto.digestStringAsync(
		// Crypto.CryptoDigestAlgorithm.SHA512,hashFORMATTED);
		
		// const redirect = `https://payments.nuvei.com/merchant/paymentpage?TERMINALID=${terminalID}&ORDERID=${OrderId}&CURRENCY=${currency}&AMOUNT=${amount}&DATETIME=${date}&RECEIPTPAGEURL=${receiptpage}&SECRET=${secret}&HASH=${digest}`;
		
		// window.open(redirect, "_self");
	
			
		//}
		// else{
		// 	// console.log(price);
		// 	if(price == ''){
		// 		alert("enter price in input field");
		// 		return;
		// 	}
		// 	if(price >= 2000){
		// 		alert("you have entered an invalid amount");
		// 		return
		// 	}

		// 	const datey = moment().format('DD-MM-YYYY:hh:mm:ss:sss');
		// 	const date = datey.slice(0, -1);
		// 	const hasheddate = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, date);
		// 	const hashedslice = hasheddate.substring(20,15);
		// 	const amount = price;
		// 	const terminalID = '56614001';
		// 	const OrderId = hashedslice;
		// 	const currency = "USD";
		// 	const secret ='neverlandherewecome2910';
		// 	const receiptpage = 'https://kcbsoftware.com/app4ureceipt';
		// 	// const hashFORMATTED = `${terminalID}:${OrderId}:USD:${amount}:${date}:${receiptpage}:${secret}`;
		// 	const hashFORMATTED = `${terminalID}:${OrderId}:${currency}:${amount}:${date}:${receiptpage}:${secret}`;
		// 	const digest = await Crypto.digestStringAsync(
		// 		Crypto.CryptoDigestAlgorithm.SHA512,hashFORMATTED);
			
		// 	const redirect = `https://payments.nuvei.com/merchant/paymentpage?TERMINALID=${terminalID}&ORDERID=${OrderId}&CURRENCY=${currency}&AMOUNT=${amount}&DATETIME=${date}&RECEIPTPAGEURL=${receiptpage}&SECRET=${secret}&HASH=${digest}`;
			
		// 	window.open(redirect, "_self");
			
		// }
		

  }

  return (
    <SafeAreaView>
      <ScrollView>
        <Image
          style={styles.image}
          source={packageI.image}
        />
        <View style={styles.infoContainer}>
          <Text style={styles.name}>{packageI.name}</Text>
          {/* <Text style={styles.price}>$ {packageI.price}</Text> */}
		  {/* {price} */}
		  {packageI.id != 5 &&
			  <Text style={styles.price}>$ {packageI.price}</Text> }
			  {
				  packageI.id == 5 && 
			<View><Text>$</Text>
			<TextInput
			placeholder="enter price" defaultValue={price} onChangeText={(value)=>{setPrice(value)}} style={styles.input}/>
			</View>
			  }
          <Text style={styles.description}>{packageI.details}</Text>
          <TouchableOpacity style={styles.butbut} onPress={(() => SubmitInfo())}>
              <Text style={{fontWeight: 'bold',color: 'white', fontSize: 23}}>Buy Now</Text>
          </TouchableOpacity>
			<FontAwesome name="cc-visa" size={32} color="black"/>
			<FontAwesome name="cc-mastercard" size={32} color="black"/>
			<FontAwesome name="cc-amex" size={32} color="black"/>
            {/* <Button
            onPress={onAddToCart}
            title="Add to cart"
            / > */}
                {/* {packageI.button} */}
                
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    borderRadius: 16,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowColor: 'black',
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 1,
    marginVertical: 20,
  },
  image: {
    height: 300,
    width: '100%'
  },
  infoContainer: {
    padding: 16,
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  price: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    fontWeight: '400',
    color: '#787878',
    marginBottom: 16,
  },
  butbut:{
    padding:'30px',
    backgroundColor: colours.third,
    alignItems:'center',

},
input:{
	width:'150px',
	
}
});
import React, { useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Link, NavigationContainer, useNavigation } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import * as Linking from 'expo-linking';

import Header from './app/components/Header';
import Login from './app/screens/Login';
import Dashboard from './app/screens/Dashboard';
import CustomDrawer from './app/components/CustomDrawer';
import colours from './app/config/colours';
import Products from './app/screens/Products';
import LandingPage from './app/screens/LandingPage';
import Contact from './app/screens/Contact';
import Advantage from './app/screens/Advantage';
import Terms from './app/screens/Terms';
import privacy from './app/screens/privacy';
import {Cart}  from './app/screens/Cart.js'; 
import  {ProductDetails}  from './app/screens/ProductDetails';
import { CartProvider } from './app/config/CartContext';
import Register from './app/screens/Register';
import Checkout from './app/screens/Checkout';
import receiptpage from './app/screens/receiptpage';
import fillout from './app/screens/fillout';

const Stack = createNativeStackNavigator();;
const Drawer = createDrawerNavigator();


export default function App() {
	const [data, setData] = useState(null);
	useEffect(()=>{
		
		const link = Linking.createURL('receipt');
		console.log(link);
		// Linking.addEventListener("url", handleDeepLink);
		// return()=>{
		// 	Linking.removeEventListener("url");
		// }
	},[]);
	function handleDeepLink(event){
		let data = Linking.parse(event.url);
		setData(data);
	}
 
  return (
   
    <CartProvider>
    <NavigationContainer styles={styles.app}>
    <Stack.Navigator >
      <Stack.Screen name="Routes" component={DrawerRoutes} options={{headerShown: false}}/>
      <Stack.Screen name="Login" component={Login} options={{headerShown: false}}/>
      <Stack.Screen name="Register" component={Register} options={{headerShown: false}}/>
      <Stack.Screen name="Contact" component={Contact} options={{headerShown: false}}/>
      <Stack.Screen name="Advantage" component={Advantage} options={{headerShown: false}}/>
      <Stack.Screen name="Cart" component={Cart} options={{headerShown: false}}/>
      <Stack.Screen name="LandingPage" component={LandingPage} options={{headerShown: false}}/>
      <Stack.Screen name="ProductDetails" component={ProductDetails}/>
      <Stack.Screen name="Checkout" component={Checkout}/>
      <Stack.Screen name="fillout" component={fillout}/>
      <Stack.Screen name="terms" component={Terms}/>
      <Stack.Screen name="privacy" component={privacy}/>
      <Stack.Screen name="receipt" path={'http://localhost:19006/receipt'} component={receiptpage}/>
      <Stack.Screen  name="Products" component={Products} options={{headerShown: false}}/>
      {/* <Stack.Screen name="Dashboard" component={Dashboard} options={{headerShown: false}}/> */}
    </Stack.Navigator>
  </NavigationContainer>
   </CartProvider>
  
  );
}
function DrawerRoutes(){
  // const {products} = Data;
  return(
    <Drawer.Navigator initialRouteName='Login' drawerContent={props => <CustomDrawer {...props}/>}>

    {/* <Drawer.Screen  name="Dashboard" component={Dashboard} options={{headerStyle:{backgroundColor: colours.secondary}, headerTitle: (props) => <Header{...props}/>}}/> */}

<Drawer.Screen name="Home" component={LandingPage} options={{headerStyle:{backgroundColor: colours.third}, headerTitle: (props) => <Header{...props}/>}}/>
<Drawer.Screen name="Advantage" component={Advantage} options={{headerStyle:{backgroundColor: colours.third}, headerTitle: (props) => <Header{...props}/>}}/>
<Drawer.Screen name="Products" component={Products} options={{headerStyle:{backgroundColor: colours.third}, headerTitle: (props) => <Header{...props}/>}}/>
<Drawer.Screen name="Contact" component={Contact} options={{headerStyle:{backgroundColor: colours.third}, headerTitle: (props) => <Header{...props}/>}}/>
<Drawer.Screen name="Form" component={fillout}/>
{/* <Drawer.Screen name="Cart" component={Cart} options={{headerStyle:{backgroundColor: colours.secondary}, headerTitle: (props) => <Header{...props}/>}}/> */}

    
    
  </Drawer.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  app:{
    fontFamily: 'georgia bold',
  }
});

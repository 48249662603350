import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import Header from '../components/Header';
import colours from '../config/colours';

export default function Dashboard() {
  return (
      <View styles={styles.container}>
        <Text >Get Your App or Website Started Today</Text>
    </View>
  );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'georgia bold',
    },
  });
  
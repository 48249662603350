import { View, Text, StyleSheet } from 'react-native'
import { useNavigation} from '@react-navigation/native';
import React from 'react'
import colours from '../config/colours';

export default function Footer() {
	const navigation = useNavigation();
  return (
	  
	<View style={styles.footer}> 
		<View style={styles.anchorContainer}>
			<Text style={styles.footeranchor} onPress={()=>{navigation.navigate("Home")}}>Home</Text>
			<Text style={styles.footeranchor} onPress={()=>{navigation.navigate("Advantage")}}>Advantage</Text>
			<Text style={styles.footeranchor} onPress={()=>{navigation.navigate("Contact")}}>Contact Us</Text>
			<Text style={styles.footeranchor} onPress={()=>{navigation.navigate("Products")}}>Products</Text>
		</View>
	  <Text style={styles.copyright}>@Copyright 2022 App4U with association with KCB Software & Design<br/> Operated out of Vancouver BC Canada</Text>
		<View style={{position: 'absolute', bottom: 0, left: '5px'}}>
			<Text style={{color: 'white', fontFamily: 'georgia bold', paddingBottom: '10px'}} onPress={()=> navigation.navigate('terms')}>Terms & Conditions</Text>
			<Text style={{color: 'white', fontFamily: 'georgia bold', paddingBottom: '7px'}} onPress={()=> navigation.navigate('privacy')}>Privacy Policys</Text>
		</View>
	  </View>
  )
}
const styles = StyleSheet.create({
	footer:{
		height: '200px',
		backgroundColor: colours.third,
		
	},
	copyright:{
		color: 'white',
		position: 'absolute',
		bottom: 0,
		right: '37%',
		textAlign: 'center',

	},
	anchorContainer:{
		flexDirection: 'row',
		marginLeft: '20%',
		marginRight: '10%',
		marginTop: '50px',
		// width: '200px',
	},
	footeranchor:{
		paddingHorizontal: '40px',
		color: 'white',
		padding:'10px',
		fontFamily: 'georgia bold',
		fontSize:15,
	},

})
import { View, Text, TextInput,TouchableOpacity, StyleSheet } from 'react-native'
import React, { Component } from 'react'
import { useNavigation } from '@react-navigation/native';
import { getFunctions, httpsCallable} from 'firebase/functions';
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 
import {app} from '../config/firebase';
import colours from '../config/colours';
import Footer from '../components/Footer';
const firebaseFunctions = getFunctions(app);
const firestore = getFirestore();

export default class Contact extends Component {
	constructor(){
		super();
		this.state={
			Name: '',
			company: '',
			email: '',
			phone: '',
			comment: '',
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
	updateInputVal = (val, prop) => {
        const state = this.state;
        state[prop] = val;
        this.setState(state);
      }
	   onSubmit =()=>{
		// e.preventDefault();
		this.textInput.clear();
		this.nameInput.clear();
		this.busInput.clear();
		this.phoneInput.clear();
		this.emailInput.clear();
		const sendEmail = httpsCallable(firebaseFunctions, "sendEmail", app);
		console.log(this.state.Name);
		sendEmail({
			name: this.state.Name,
			company: this.state.company,
			email: this.state.email,
			phone: this.state.phone,
			comment: this.state.comment,
		}).then(result =>{
			console.log("email sending result:" + result);
			alert("Email Sent Successfully. We will contact you within 24 hours");
		}
	).catch(error =>{
			console.log(error);
			console.log("frontend error");
			console.log(error.code);
			console.log(error.message);
			console.log(error.details);
		})
	

	  }
	render(){
		return (

		<View>
			<View style={styles.container}>
				<Text style={styles.label}>Please fill out the form and we will contact you within 24 hours</Text>
	      <View style={styles.form}>
			  <Text style={styles.label}>Full Name</Text>
	      	<TextInput
			  style={styles.input}
			  ref={input => { this.nameInput = input }}
			  placeholder="Full Name"
			  value={this.state.Name}
			  onChangeText={(val)=> this.updateInputVal(val, 'Name')}
			  /><br/>
			  <Text style={styles.label}>Business Name</Text>
	      	<TextInput
			  style={styles.input}
			  ref={input => { this.busInput = input }}
			  placeholder="Business Name"
			  value={this.state.company}
			  onChangeText={(val)=> this.updateInputVal(val, 'company')}
			  /><br/>
			  <Text style={styles.label}>Email</Text>
		      <TextInput
			  style={styles.input}
			  ref={input => { this.emailInput = input }}
			  placeholder="Email"
			  value={this.state.email}
			  onChangeText={(val)=> this.updateInputVal(val, 'email')}
			  /><br/>
			  <Text style={styles.label}>Phone Number</Text>
		      <TextInput
			  style={styles.input}
			  ref={input => { this.phoneInput = input }}
			  placeholder="Phone Number"
			  value={this.state.phone}
			  onChangeText={(val)=> this.updateInputVal(val, 'phone')}
			  /><br/>
			  <Text style={styles.label}>Comment</Text>
			  <TextInput
			  style={styles.input}
			  ref={input => { this.textInput = input }}
			  placeholder="Comments..."
			  value={this.state.comment}
			  onChangeText={(val)=> this.updateInputVal(val, 'comment')}
			  multiline = {true}
			  numberOfLines = {10}
			  /><br/>
			  <TouchableOpacity
			  style={styles.button}
			  onPress={()=> this.onSubmit()}>Submit</TouchableOpacity>
	      </View>
		<Text style={styles.label}>
			804-1333 Haro Street, V6E1G4 ,Vancouver BC, Candada<br/> 
			information.app4u@gmail.com
		</Text>
	    </View>
		  <Footer/>
		</View>
  )
}
}
const styles = StyleSheet.create({
	container:{
		alignContent: 'center',
		alignItems: 'center',
	},
	form:{
		marginTop: "40px",
		padding: '40px',
		backgroundColor: colours.secondary,
		width: '80%',
		borderRadius: 10,
	},
	input:{
		backgroundColor:'white',
	},
	button:{
		textAlign: 'center',
		backgroundColor: colours.third,
		padding: '30px',
		borderRadius:15,
		fontWeight: 'bolder'
	},
	label:{
		fontWeight: 'bold',
		fontFamily: 'georgia bold',

	}

})
import { View, Text, Image, StyleSheet } from 'react-native'
import React, { useEffect } from 'react'
import colours from '../config/colours'
import Footer from '../components/Footer'

export default function Advantage() {
	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])
  return (
	<View>
		<Image style={styles.topImage} source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644973774/App4u/thumbs_vs59sd.jpg'} alt=""/>
	 <View  style={styles.container}> <Text style={styles.start}>The App4U Advantage</Text>
	  <Text style={styles.texty}>What are the Advantages of Using App4U? <br/><br/></Text>
	  <View style={styles.text}>
	  	<Text style={styles.texty}>Get A Unique & Innovative Product,
	No two businesses are exactly the same so it makes sense for businesses to seek software that meets their unique needs. Having a personalized software product made for a business helps ensure that the software contains all of the features and functionalities that the organization needs to streamline business processes.
	Software developers can create software that aligns with business goals and provides organizations with ample opportunity to grow. Custom business software is able to complement a working model better than any off-the-shelf software ever could since it is designed to support a specific business’s identity. <br/><br/>
	</Text>
	<Text style={styles.texty}>
	Every business aims to grow the scale of their operations over time. This usually involves the creation of new products, services and processes. Many times, off-the-shelf software is not equipped to handle the growth of a business, resulting in a loss of productivity and functionality. When businesses attempt to modify their off-the-shelf software to accommodate their growth, bugs can occur, potentially causing business disruptions.
	One of the advantages of custom software development is that it is capable of handling business growth in a bug-free manner. This means that businesses can continue using and updating their custom software for many years without having to purchase new products as off-the-shelf. Additionally, some off-the-shelf software may require a yearly license renewal, whereas custom software development is a one-time investment with lifetime product maintenance and support.
	<br/><br/></Text>
	<Text style={styles.texty}>
	Some businesses are hesitant to invest in custom software development due to cost. Although custom software development typically costs more upfront compared to off-the-shelf software, businesses receive more in return for their dollar. Custom software development is a wise investment for businesses typically saving companies money in the long run.
	There is no need to purchase additional hardware, acquire licenses or pay for features that employees are likely never going to use. In return for an initial investment, businesses that choose custom software development have the opportunity to create personalized software from scratch.
	<br/><br/></Text>
	{/* <Text style={styles.texty}>How Do I know If App4U is right for me and my buisness</Text> */}
	  </View>
</View>
<View style={styles.whitespace}></View>
<Footer/>
	</View>
  )
}
const styles = StyleSheet.create({
	container:{
		marginLeft:'30px',
	},
	topImage:{
        height: "500px",
        width: 'auto',
		
    },
	start:{
		fontSize:38,
		fontWeight:'bold',
		color: colours.forth,
		paddingBottom:'40px',
		fontFamily: 'georgia bold',
		marginTop: '40px',
	},
	texty: {
		fontFamily: 'georgia bold',
		// whiteSpace: 'pre-line',
		fontSize: 17,
	},
	text:{
		maxWidth: '60%',

	},
	whitespace:{
		height: '300px',
		width: '100%',
	   
	}
})
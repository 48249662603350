import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { WebView } from 'react-native-webview';
import { getProduct } from '../config/cartData';
import React, {useEffect, useState, useContext} from 'react';

const HTML = `<html> <body> <form action="https://testpayments.nuvei.com/merchant/paymentpage" method="post"> <input type="hidden" name="TERMINALID" value="1064140" /> <input type="hidden" name="ORDERID" value="testOrder" /> <input type="hidden" name="CURRENCY" value="USD" /> <input type="hidden" name="AMOUNT" value="1.23" /> <input type="hidden" name="DATETIME" value="24-09-2021:15:31:11:111" /> <input type="hidden" name="HASH" value="56083f2c6aa3d233dade436b1308805a" /> <input type="submit" value="Pay Now" /> </form> </body> </html>`
export default function Checkout({route}) {
	const {productid} = route.params;
	const [packageID, setProduct] = useState({});
	useEffect(() => {
		setProduct(getProduct(productid));
		console.log(packageID)
		console.log()
	  });
  return (
	<View>
	 
	</View>
  )
}
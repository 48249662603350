import { useEffect, useState} from 'react';
import { View, Text, Image, FlatList, StyleSheet } from 'react-native';
import colours from '../config/colours';
import {WebData} from '../config/cartData';
// import {AppData} from '../config/cartData';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/firebase';
import Footer from '../components/Footer';


// import { getProducts } from '../config/cartData';



export default function Products() {
    const navigation = useNavigation();


    useEffect(()=>{
		window.scrollTo(0, 0)
        onAuthStateChanged(auth, (user)=>{
            if(user)
            {
                console.log("");
            }
            else{
                navigation.navigate("Login");
            }
        })
    },[])
  return (
    <View styles={styles.container}>
		<Image style={styles.topImage}source={'https://res.cloudinary.com/kcb-software-design/image/upload/v1644976040/App4u/products_ynjgxu.jpg'} alt=""/>
      {/* <Text style={styles.productText}>Products</Text> */}
      <Text style={styles.web}>Websites:</Text>
     
      <FlatList
        data={WebData}
        renderItem={({item})=>(
                <TouchableOpacity onPress={()=>{navigation.navigate('ProductDetails',{
                    productId: item.id,
                })}}>
            <View style={styles.productsListContainer}>
            <Text style={styles.names} onPress={()=>{navigation.navigate('ProductDetails',{
                productId: item.id,
            })}}>{item.name}: ${item.price}</Text>
            <Image source={item.image} style={styles.image} />
            </View>
            </TouchableOpacity>
        )}
      />
      {/* <Text style={styles.web}>Mobile Applications:</Text>
      <FlatList
        data={AppData}
        renderItem={({item})=>(
            <View style={styles.productsListContainer} onPress={() => {
                navigation.navigate('ProductDetails',{
                    productId: item.id,
                })
            }}>
            <Text style={styles.names} onPress={()=>{navigation.navigate('ProductDetails',{
                    productId: item.id,
                })}}>{item.name}</Text>
            </View>
        )}
        /> */}
      
	  <View style={styles.whitespace}></View>
	  <Footer/>
    </View>
  );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: "100%",
		paddingBottom: '200px',
      },
	  topImage:{
        height: "500px",
        width: 'auto',
		
    },
      productText:{
        fontFamily: 'georgia bold',
        fontSize: 50,
        textAlign: 'center',
        marginTop: '30px',
        color: 'black',
      },
      web:{
        marginLeft:'30px',
        fontSize: 38,
        fontFamily: 'georgia bold',
        color: colours.primary,
      },
      productsList: {
        backgroundColor: '#eeeeee',
      },
      productsListContainer: {
        // backgroundColor: colours.primary,
        paddingVertical: 10,
        marginHorizontal: 100,
        width: '350px',
        height: '200px',
        marginTop: '20px',
		// paddingBottom: '200px',
    },
    names:{
        color:'black',
        fontSize:20,
        fontFamily: 'georgia bold',

      },
      image:{
        width:'100%',
        height: '100%',
      },
      butbut:{
          padding:'30px',
          backgroundColor: colours.third,
          alignItems:'center',

      },
	  whitespace:{
		  height: '300px',
		  width: '100%',
		 
	  }
});
import React, { useState, useEffect } from 'react';
import { useNavigation, DrawerActions } from '@react-navigation/native';
import { View, KeyboardAvoidingView, TextInput, StyleSheet, Text} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {auth} from '../config/firebase.js';
import colours from '../config/colours.js';


export default function Login() {
  const navigation = useNavigation();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() =>{
      console.log("made it");
      const unsubscribe = auth.onAuthStateChanged(user => {
          if(user){
              navigation.navigate("Home");
          }
      })
      return unsubscribe
  }, []);
  const handleSignUp = () =>{
    navigation.navigate("Register");
    // auth.createUserWithEmailAndPassword(email, password)
    // .then(userCredentials => {
    //     const user = userCredentials.user;
    //     console.log('Registered with:', user.email);
    // })
    // .catch(error => alert(error.message))
}
const handleLogin = () => {
    auth.signInWithEmailAndPassword(email, password)
    .then(userCredentials =>{
        const user = userCredentials.user;
        console.log('Logged in with:', user.email);
    })
    .catch(error=> alert(error.message));
}
  return (
    <KeyboardAvoidingView style={styles.container}>
      <Text style={styles.title}>APP4U</Text>
    <View style={styles.inputContainer}>
        <TextInput placeholder="Email" 
        value={email} onChangeText={text => setEmail(text)}
        style={styles.input}
        />
        <TextInput placeholder="Password" 
        value={password} onChangeText={text => setPassword(text)}
        style={styles.input}
        secureTextEntry
        />
    </View>
    <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={handleLogin} style={styles.button}>
            <Text styles={styles.buttonText}>Login</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={handleSignUp} style={[styles.button, styles.buttonOutline]}>
            <Text styles={styles.buttonOutlineText}>Register</Text>
        </TouchableOpacity>
    </View>
</KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container:{
      flex:1,
      justifyContent: 'center',
      alignItems: 'center',

  },
inputContainer:{
  width: '80%',
},
input:{
  backgroundColor: 'white',
  paddingHorizontal: 15,
  paddingVertical: 10,
  borderRadius:10,
  marginTop: 5
},
buttonContainer:{
  width: '60%',
  justifyContent: 'center',
  alignItems:'center',
},
button:{
  backgroundColor: colours.primary,
  width: '100%',
  padding: 15,
  borderRadius: 10,
  alignItems: 'center'
},
buttonOutline:{
  backgroundColor: colours.secondary,
  marginTop: 5,
  borderColor: 'black',
  borderWidth: 2,
},
buttonText:{
  color: 'white',
  fontWeight: '700',
  fontSize: 16,
},
buttonOutlineText:{
  fontWeight: '700',
  fontSize: 16,
  color: 'black'
},
title:
{
  fontSize:52,
  fontFamily: 'georgia bold',
  fontWeight:'bold',
  color: colours.primary,
}
})

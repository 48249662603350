import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
require('firebase/auth');

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyAFN0_X1WDFDr2Z1_09igPffVqGauRV3xc",
    authDomain: "app4u-af78d.firebaseapp.com",
    projectId: "app4u-af78d",
    storageBucket: "app4u-af78d.appspot.com",
    messagingSenderId: "622397855586",
    appId: "1:622397855586:web:fa5da066481a34fbcb3e3b",
    measurementId: "G-H0RS9B9K9T"
  };
// Initialize Firebase
// let app;
// if(firebase.apps.length === 0){
  export const app = firebase.initializeApp(firebaseConfig);
// }
// else{
//     app = firebase.app()
// }
const auth = firebase.auth()

export { auth };
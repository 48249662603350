import { View, SafeAreaView, Text } from 'react-native';
import React from 'react';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import {auth} from '../config/firebase.js';
import colours from '../config/colours';

const CustomDrawer = (props) =>{
    const navigation = useNavigation();

    const handleSignOut = ()=>{
        auth.signOut()
        .then(()=>{
          navigation.navigate("Login")
        })
        .catch(error=> alert(error.message));
      }
      return(
        <SafeAreaView>
        <DrawerContentScrollView {...props} >
          
            <DrawerItemList {...props}/>
           
            <TouchableOpacity style={{backgroundColor: colours.primary, padding: '30px' }}>
                <Text style={{color: 'white'}}onPress={handleSignOut} >Sign Out</Text>
          </TouchableOpacity>
      
        </DrawerContentScrollView>
        </SafeAreaView>
      );
}
export default CustomDrawer
import { View, Text, StyleSheet } from 'react-native';
import React, { useContext, useEffect} from 'react';
import colours from '../config/colours';
import { CartContext} from '../config/CartContext';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';
import { withOrientation } from 'react-navigation';
import { RollInRight } from 'react-native-reanimated';


export default function Header() {
    const navigation = useNavigation();
    const {getItemsCount} = useContext(CartContext);
   
  return (
    <View style={styles.container}>
      <View style={styles.nameCon}><Text style={styles.name}>APP4U</Text></View>
      <View>
          {/* <Text style={styles.cart, styles.carttext}>({getItemsCount()}))</Text> 
          <Icon name="ios-cart" size={30} style={styles.cart} onPress={() =>{
              navigation.navigate('Cart');
          }}/> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colours.third,
      alignItems: 'center',
      justifyContent: 'center',
    //   width: '100%',
    //   height: '100%',
    },
    nameCon:{
        // marginLeft: '200px', 
        textAlign: 'center',
    },
    name:{
        color: 'white',
        fontSize: 25,
        fontWeight: 'bolder',
        fontFamily: 'georgia bold',
    },
    cart:{
        textAlign: 'right',
        position:'absolute',
        marginLeft:'50vw',
        width: '100%',
        // alignSelf: 'flex-end',
        // paddingLeft: "10px",
        // padding: '10px',
    },
    carttext:{
        position:'absolute',
        marginLeft:'48vw',
    }
  });

import {  StyleSheet, Text, View, TextInput, Button, Alert, ActivityIndicator, CheckBox} from 'react-native';
import React, { Component } from 'react';
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from 'firebase/firestore'; 
import colours from '../config/colours';
import {auth, app} from '../config/firebase.js';

const firestore = getFirestore(app);
const db = collection(firestore, 'users');

export default class Register extends Component {

    constructor() {
        super();
        this.state = { 
          displayName: '',
          email: '', 
          password: '',
          business: '',
          businessType: '',
		  terms: false,
          isLoading: false
        }
      }

	  acceptterms = ()=>{
		  if(this.state.terms == false){
			  this.setState({terms: true})
		  }
		  else{
			this.setState({terms: false})
		  }
	  }
    updateInputVal = (val, prop) => {
        const state = this.state;
        state[prop] = val;
        this.setState(state);
      }

	addDB = async()=>{
		if(this.state.email == '' || this.state.password == '' || this.state.displayName == '' || this.state.businessType == '' || this.state.business == '') {

			alert('Please enter all required fields')
		  } 
		  if(this.state.terms == false){
			  alert('you must agree to our terms and conditions before creating an account')
		  } else{
			  await addDoc(db,{
					  name: this.state.displayName,
				  business: this.state.business,
				  businessType: this.state.businessType,
				  email: this.state.email
			  })
				  console.log('Your doc was created at ');
				  this.registerUser();

		  }
	}




    registerUser = () => {
		if(this.state.password.length < 6)
		{
			alert("Please enter a Password with at least 6 characters");
			return;
		}
          this.setState({
            isLoading: true,
          })
          auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
          .then((res) => {
			  console.log("in then");
            res.user.updateProfile({
              displayName: this.state.displayName
            })
            console.log('User registered successfully!')
            this.setState({
              isLoading: false,
              displayName: '',
              email: '',
              password: ''
            })
            this.props.navigation.navigate('Login')
          })
          .catch(error => {
			alert("invalid email or password, make sure password has letters in it" + error);
			this.setState({ errorMessage: error.message })})      
       // }
      }

  render() {
    if(this.state.isLoading){
        return(
          <View style={styles.preloader}>
            <ActivityIndicator size="large" color="#9E9E9E"/>
          </View>
        )
      }    
    return (
        <View style={styles.container}>  
        <TextInput
          style={styles.inputStyle}
          placeholder="Full Name"
          value={this.state.displayName}
          onChangeText={(val) => this.updateInputVal(val, 'displayName')}
        />      
        <TextInput
          style={styles.inputStyle}
          placeholder="Business Name"
          value={this.state.business}
          onChangeText={(val) => this.updateInputVal(val, 'business')}
        />      
        <TextInput
          style={styles.inputStyle}
          placeholder="Industry Of Business"
          value={this.state.businessType}
          onChangeText={(val) => this.updateInputVal(val, 'businessType')}
        />      
        <TextInput
          style={styles.inputStyle}
          placeholder="Email"
          value={this.state.email}
          onChangeText={(val) => this.updateInputVal(val, 'email')}
        />
        <TextInput
          style={styles.inputStyle}
          placeholder="Password"
          value={this.state.password}
          onChangeText={(val) => this.updateInputVal(val, 'password')}
          maxLength={15}
          secureTextEntry={true}
        />
		<View style={styles.checkboxContainer}>
		<CheckBox
		value={this.state.terms}
		onValueChange={this.acceptterms} style={styles.checkbox}
		/>   
		<Text>Do you agree to our </Text><Text style={{color: colours.third}}onPress={()=> this.props.navigation.navigate('terms')}>terms & conditions?</Text>
		</View>
        <Button
          color="#3740FE"
          title="Signup"  
          onPress={() => this.addDB()}
        />
          
        <Text 
          style={styles.loginText}
          onPress={() => this.props.navigation.navigate('Login')}>
          Already Registered? Click here to login
        </Text>                          
      </View>
    );
  }
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 35,
      backgroundColor: colours.background
    },
    inputStyle: {
      width: '100%',
      marginBottom: 15,
      paddingBottom: 15,
      alignSelf: "center",
      borderColor: colours.black,
      borderBottomWidth: 1
    },
    loginText: {
      color: '#3740FE',
      marginTop: 25,
      textAlign: 'center'
    },
    preloader: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff'
    },
	checkboxContainer:{
		flexDirection: "row",
    marginBottom: 20,
	},
	checkbox:{
		alignSelf: "center",
	}
  });
